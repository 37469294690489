import styled from "styled-components"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Swiper } from "swiper/react"
import SecondaryTitle from "../../common/SecondaryTitle"

export const OurServicesSection = styled.section`
  display: flex;
  padding: 2rem 1rem 0rem 1rem;
  flex-direction: column;
  align-items: center;
  background: var(--UI-Light-Gray, #f8f8f8);
  height: 752px;

  z-index: 1;
  position: relative;

  @media (min-width: 800px) {
    padding: 2rem 1rem 3.5625rem 1rem;
  }
  @media (min-width: 64em) {
    padding: 4.5rem 0rem 7.125rem 0rem;
  }

  @media (min-width: 960px) and (max-width: 1085px) {
    height: 752px;
  }
`

export const OurServicesTitle = styled(SecondaryTitle)`
  @media (max-width: 800px) {
    margin-bottom: 1rem;
  }
`

export const ServiceBox = styled.div`
  display: flex;
  padding: 2rem 1.85rem 2rem 1.85rem;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: content-box;
  overflow: hidden;

  background: #fff;

  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05),
    0px 10px 25px 0px rgba(50, 50, 93, 0.1);

  height: ${props => props.height};
  transition: height 0.3s ease-out;
  width: 300px;

  @media (min-width: 800px) and (max-width: 870px) {
    width: 211px;
  }

  @media (min-width: 870px) and (max-width: 960px) {
    width: 220px;
  }

  @media (min-width: 960px) and (max-width: 1085px) {
    width: 250px;
  }

  @media (min-width: 1085px) {
    padding-bottom: 2rem;
  }
`

export const CTA = styled.div`
  ${props =>
    props.expandableOpen
      ? `
      opacity: 0;
      pointer-events: none;
      height: 0;
      margin: 0;
  `
      : `
  opacity: 1;
  `}
  transition: opacity 0.3s ease-out;

  z-index: 1;
  color: ${props => props.color || "#7052CD"};
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  min-height: 144px;
  @media (min-width: 1085px) {
    min-height: auto;
  }

  @media (max-width: 340px) {
    min-height: 160px;
  }

  @media (min-width: 340px) and (max-width: 380px) {
    min-height: 152px;
  }

  @media (min-width: 800px) and (max-width: 960px) {
    min-height: 176px;
  }
`

export const BoxTitle = styled.h3`
  color: #000;
  font-family: Catamaran;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;

  @media (min-width: 340px) {
    line-height: 2rem;
  }

  @media (min-width: 1085px) {
    font-size: 1.5rem;
  }
`

export const BoxParagraph = styled.p`
  /* Desktop/Text/Body1 */
  font-family: var(--ff-primpary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.0015rem;

  @media (min-width: 1085px) {
    font-size: 1rem;
  }
`

export const StyledSwiper = styled(Swiper)`
  padding-top: 1rem !important;
  padding-bottom: 3rem !important;
  padding-right: 9px !important;
  padding-left: 9px !important;
  width: calc(100% + 18px) !important;
  margin-left: -9px !important;

  .swiper-wrapper {
    justify-content: center;
  }

  .swiper-slide {
    max-width: 275px;
    text-align: left;
  }

  .swiper-pagination {
    ${props =>
      props.expandableOpen
        ? `
          opacity: 0;
        `
        : `
          opacity: 1;
          transition: opacity 0s ease 0.3s;
        `}
  }

  @media (min-width: 340px) {
    .swiper-slide {
      max-width: 295px;
    }
  }

  @media (min-width: 380px) {
    .swiper-slide {
      max-width: 340px;
    }
  }

  @media (min-width: 64em) {
    width: 100% !important;
    margin-left: 0 !important;
  }
`

export const MobileCarousel = styled.div`
  text-align: left;
  @media (min-width: 800px) {
    display: none;
  }
`

export const ServicesBoxesDesktop = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: flex;
    gap: 1rem;
  }
  @media (min-width: 1150px) {
    gap: 3rem;
  }
`

export const ListItem = styled.div`
  border-radius: 1rem;
  background: inherit;
  text-align: left;

  color: inherit;
  background: ${props => props.background || "blue"};
  font-family: var(--ff-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  padding: 16px;
`

export const ExpandableBox = styled.div`
  display: ${props => props.display || "flex"};
  flex-direction: column;
  gap: 1rem;
  color: #293056;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const Bold = styled.span`
  font-weight: 700;
`
