import React from "react"
import styled from "styled-components"
import SecondaryTitle from "../common/SecondaryTitle"
import ServicesDevIcon from "../../assets/svgs/services-dev.svg"
import ServicesCloudIcon from "../../assets/svgs/services-cloud.svg"
import ServicesDesignIcon from "../../assets/svgs/services-design.svg"
import ServicesAgileIcon from "../../assets/svgs/services-agile.svg"
import ServicesTestIcon from "../../assets/svgs/services-test.svg"
import ServicesDataIcon from "../../assets/svgs/services-data.svg"

const StyledSkillsSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 5.5rem;

  @media (min-width: 64em) {
    margin-top: 7.5rem;
  }
`

const SkillGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  max-width: 100%;
  margin-inline: auto;

  @media (min-width: 55em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 82.75em) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
  }
`

const SkillBox = styled.div`
  display: flex;
  width: min(21.875rem, 100% - 2.5rem);
  padding: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  margin-inline: auto;

  box-shadow: rgb(122, 121, 121, 0.1) 0px 0px 0px 1px,
    0px 2px 10px 0px rgba(50, 50, 93, 0.1);

  @media (min-width: 40em) {
    width: 25.2rem;
  }
`

const TitleIconContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
`

const SkillBoxTitle = styled.h3`
  font-family: var(--ff-secondary);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;

  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;

  @media (min-width: 40em) {
    font-size: 1.5rem;
    line-height: 32px;
  }
`

const SkillBoxParagraph = styled.p`
  font-family: var(--ff-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0015rem;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 40em) {
    font-size: 1rem;
  }
`

const SkillTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`

const SkillTag = styled.div`
  background: ${props => props.color};
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
`

const SkillsSection = () => {
  return (
    <StyledSkillsSection>
      <SecondaryTitle>Our Skills And Expertise</SecondaryTitle>
      <SkillGrid>
        <SkillBox>
          <TitleIconContainer>
            <ServicesDevIcon />
            <SkillBoxTitle>SOFTWARE ENGINEERING</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#ffc2d1">Java</SkillTag>
              <SkillTag color="#ffc2d1">React</SkillTag>
              <SkillTag color="#ffc2d1">JavaScript</SkillTag>
              <SkillTag color="#ffc2d1">.Net</SkillTag>
              <SkillTag color="#ffc2d1">C#</SkillTag>
              <SkillTag color="#ffc2d1">NodeJs</SkillTag>
              <SkillTag color="#ffc2d1">Kotlin</SkillTag>
              <SkillTag color="#ffc2d1">Typescript</SkillTag>
              <SkillTag color="#ffc2d1">Clojure</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
        <SkillBox>
          <TitleIconContainer>
            <ServicesDataIcon />
            <SkillBoxTitle>DATA & AI</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#CFB8FF">Power BI</SkillTag>
              <SkillTag color="#CFB8FF">Redshift</SkillTag>
              <SkillTag color="#CFB8FF">Snowflake</SkillTag>
              <SkillTag color="#CFB8FF">NLP</SkillTag>
              <SkillTag color="#CFB8FF">AI</SkillTag>
              <SkillTag color="#CFB8FF">Apache Spark</SkillTag>
              <SkillTag color="#CFB8FF">Google BigQuery</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
        <SkillBox>
          <TitleIconContainer>
            <ServicesCloudIcon />
            <SkillBoxTitle>CLOUD & DEVOPS</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#CAE6FF">Azure</SkillTag>
              <SkillTag color="#CAE6FF">AWS</SkillTag>
              <SkillTag color="#CAE6FF">Google Cloud</SkillTag>
              <SkillTag color="#CAE6FF">Kubernetes</SkillTag>
              <SkillTag color="#CAE6FF">CD/CI</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
        <SkillBox>
          <TitleIconContainer>
            <ServicesTestIcon />
            <SkillBoxTitle>TEST AUTOMATION</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#CFB8FF">Python</SkillTag>
              <SkillTag color="#CFB8FF">Robot Framework</SkillTag>
              <SkillTag color="#CFB8FF">Test Automation</SkillTag>
              <SkillTag color="#CFB8FF">Selenium</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
        <SkillBox>
          <TitleIconContainer>
            <ServicesAgileIcon />
            <SkillBoxTitle>BUSINESS AGILITY</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#B1FCED">Agile</SkillTag>
              <SkillTag color="#B1FCED">Lean</SkillTag>
              <SkillTag color="#B1FCED">Scrum</SkillTag>
              <SkillTag color="#B1FCED">Coaching</SkillTag>
              <SkillTag color="#B1FCED">Product & Project Management</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
        <SkillBox>
          <TitleIconContainer>
            <ServicesDesignIcon />
            <SkillBoxTitle>DESIGN</SkillBoxTitle>
          </TitleIconContainer>
          <SkillBoxParagraph>
            <SkillTagContainer>
              <SkillTag color="#ffc2d1">UI</SkillTag>
              <SkillTag color="#ffc2d1">UX</SkillTag>
              <SkillTag color="#ffc2d1">Component Libraries</SkillTag>
              <SkillTag color="#ffc2d1">User Research</SkillTag>
              <SkillTag color="#ffc2d1">Design Systems</SkillTag>
            </SkillTagContainer>
          </SkillBoxParagraph>
        </SkillBox>
      </SkillGrid>
    </StyledSkillsSection>
  )
}

export default SkillsSection
