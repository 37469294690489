import React from "react"
import styled, { keyframes } from "styled-components"
import Cloud1 from "../../assets/svgs/services_blob_1.svg"
import Cloud2 from "../../assets/svgs/services_blob_2.svg"
import Cloud3 from "../../assets/svgs/services_blob_3.svg"
import Cloud4 from "../../assets/svgs/services_blob_4.svg"
import Cloud5 from "../../assets/svgs/services_blob_5.svg"
import Cloud6 from "../../assets/svgs/services_blob_6.svg"
import Cloud7 from "../../assets/svgs/services_blob_7.svg"
import Cloud8 from "../../assets/svgs/services_blob_8.svg"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

// eslint-disable-next-line no-unused-vars
const fadeInMobile = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  1.5% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }


  12% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }

  13.5% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }
`

const slideInSlideOut = keyframes`
  0% {
    transform: translateX(-200%) translate(-50%, -50%);
    opacity: 1;
  }

  1.5% {
    transform: translateX(-1%) translate(-50%, -50%);
  }

  1.8% {
    transform: translateX(0%) translate(-50%, -50%);
  }

  11.5% {
    transform: translateX(0%) translate(-50%, -50%);
  }

  12% {
    transform: translateX(-3%) translate(-50%, -50%);
  }

  13.5% {
    transform: translateX(200%) translate(-50%, -50%);
    opacity: 1;
  }

  13.6% {
    transform: translateX(200%) translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(200%) translate(-50%, -50%);
  }
`

const CloudContainer = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  animation: ${fadeIn} 0.45s ease-in forwards;
  animation-delay: ${props => props.animationIndex * 0.35}s;
  opacity: 0;
  transform-origin: ${props => props.animationOrigin || "bottom left"};

  @media (max-width: 50em) {
    top: 50%;
    left: 50%;
    transform: translateX(-200%) translate(-50%, -50%);
    animation: ${slideInSlideOut} 24s linear forwards;
    animation-delay: ${props => props.animationIndex * 3}s;
    animation-iteration-count: infinite;
    opacity: 0;
  }
`

const Text = styled.p`
  position: absolute;
  top: ${props => props.top || "49%"};
  left: ${props => props.left || "27%"};
  transform: ${props => props.translate || "translate(-12%, -50%)"};

  color: ${props => props.color};
  font-family: var(--ff-primary);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.0015rem;
`

const Cloud = ({
  index,
  text,
  color,
  leftText,
  topText,
  textPosition,
  ...props
}) => {
  const clouds = [
    Cloud1,
    Cloud2,
    Cloud3,
    Cloud4,
    Cloud5,
    Cloud6,
    Cloud7,
    Cloud8,
  ]
  const Cloud = clouds[index - 1]

  return (
    <CloudContainer {...props}>
      <Cloud />
      <Text
        color={color}
        left={leftText}
        top={topText}
        translate={textPosition}
      >
        {text}
      </Text>
    </CloudContainer>
  )
}

export default Cloud
