import React, { useRef } from "react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import { StaticImage } from "gatsby-plugin-image"
import useExpandableComponent from "../../../hooks/useExbandableComponent"

import {
  OurServicesSection,
  OurServicesTitle,
  CTA,
  Bold,
  TextContainer,
  ExpandableBox,
  BoxTitle,
  BoxParagraph,
  ServicesBoxesDesktop,
  ListItem,
  ServiceBox,
  MobileCarousel,
  StyledSwiper,
} from "./StyledComponents"

const OurServices = () => {
  // Desktop refs
  const serviceBoxRef1Desktop = useRef(null)
  const serviceBoxRef2Desktop = useRef(null)
  const serviceBoxRef3Desktop = useRef(null)

  // Mobile refs
  const serviceBoxRef1Mobile = useRef(null)
  const serviceBoxRef2Mobile = useRef(null)
  const serviceBoxRef3Mobile = useRef(null)

  // Desktop expandable components
  const expandableOneDesktop = useExpandableComponent(serviceBoxRef1Desktop)
  const expandableTwoDesktop = useExpandableComponent(serviceBoxRef2Desktop)
  const expandableThreeDesktop = useExpandableComponent(serviceBoxRef3Desktop)

  // Mobile expandable components
  const expandableOneMobile = useExpandableComponent(serviceBoxRef1Mobile)
  const expandableTwoMobile = useExpandableComponent(serviceBoxRef2Mobile)
  const expandableThreeMobile = useExpandableComponent(serviceBoxRef3Mobile)

  return (
    <OurServicesSection>
      <OurServicesTitle>How We Can Help</OurServicesTitle>
      <ServicesBoxesDesktop>
        <ServiceBox
          ref={serviceBoxRef1Desktop}
          height={expandableOneDesktop.height}
        >
          <StaticImage
            className="service-image"
            src="../../../images/services/box_1.jpg"
            style={{
              width: "200px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>Extending digital capability</BoxTitle>
            <BoxParagraph>
              Our experts quickly adapt to new domains and technologies and are
              ready to join your team.
            </BoxParagraph>
          </TextContainer>
          <ExpandableBox
            display={expandableOneDesktop.isOpen ? "flex" : "none"}
          >
            <ListItem background="#B1FCED">
              <Bold>a</Bold>. We have expertise in all core IT areas, such as
              software engineering, data, and cloud.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>b</Bold>. Our experts are specialised in complex and
              business-critical projects.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>c</Bold>. Our experts master numerous domains and industries
              such as finance, manufacturing, retail, energy, and healthcare.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>d</Bold>. We are used to working together with multi-vendor,
              distributed, and international teams.
            </ListItem>
          </ExpandableBox>
          <CTA
            onClick={expandableOneDesktop.toggle}
            expandableOpen={expandableOneDesktop.isOpen}
            color="#069D7F"
          >
            See how
          </CTA>
          <CTA
            onClick={expandableOneDesktop.toggle}
            expandableOpen={!expandableOneDesktop.isOpen}
            color="#069D7F"
          >
            Close
          </CTA>
        </ServiceBox>
        <ServiceBox
          ref={serviceBoxRef2Desktop}
          height={expandableTwoDesktop.height}
        >
          <StaticImage
            className="service-image"
            src="../../../images/services/box_2.jpg"
            style={{
              width: "181px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>A dedicated product team</BoxTitle>
            <BoxParagraph>
              Our skilled and versatile team will guide your product journey
              from the first vision to a real digital solution.
            </BoxParagraph>
          </TextContainer>
          <ExpandableBox
            display={expandableTwoDesktop.isOpen ? "flex" : "none"}
          >
            <ListItem background="#B1FCED">
              <Bold>a</Bold>. We can assemble a team quickly; either establish
              the team at once or scale it up incrementally.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>b</Bold>. We will always tailor the team roles and expertise
              to the needed business case.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>c</Bold>. We will also guide you on how to lead product
              development through the project.
            </ListItem>
          </ExpandableBox>
          <CTA
            onClick={expandableTwoDesktop.toggle}
            expandableOpen={expandableTwoDesktop.isOpen}
            color="#069D7F"
          >
            See how
          </CTA>
          <CTA
            onClick={expandableTwoDesktop.toggle}
            expandableOpen={!expandableTwoDesktop.isOpen}
            color="#069D7F"
          >
            Close
          </CTA>
        </ServiceBox>
        <ServiceBox
          ref={serviceBoxRef3Desktop}
          height={expandableThreeDesktop.height}
        >
          <StaticImage
            className="service-image"
            src="../../../images/services/box_3.jpg"
            style={{
              width: "192px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>Specialised services</BoxTitle>
            <BoxParagraph>
              We offer expertise for specialized tasks like coaching,
              architecture and audits.
            </BoxParagraph>
          </TextContainer>
          <ExpandableBox
            display={expandableThreeDesktop.isOpen ? "flex" : "none"}
          >
            <ListItem background="#B1FCED">
              <Bold>a</Bold>. We also undertake more specialised assignments,
              such as architecture, security and design audits, team coaching
              and support, and proof of concepts.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>b</Bold>. Our experts are used to working and solving
              challenges independently.
            </ListItem>
            <ListItem background="#B1FCED">
              <Bold>c</Bold>. Our compensation model allows our experts to
              engage in shorter allocations or assignments.
            </ListItem>
          </ExpandableBox>
          <CTA
            onClick={expandableThreeDesktop.toggle}
            expandableOpen={expandableThreeDesktop.isOpen}
            color="#069D7F"
          >
            See how
          </CTA>
          <CTA
            onClick={expandableThreeDesktop.toggle}
            expandableOpen={!expandableThreeDesktop.isOpen}
            color="#069D7F"
          >
            Close
          </CTA>
        </ServiceBox>
      </ServicesBoxesDesktop>

      <MobileCarousel>
        <StyledSwiper
          pagination={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          breakpoints={{
            400: {
              spaceBetween: 16,
            },
            500: {
              spaceBetween: 32,
            },
          }}
          className="mySwiper"
          modules={[Pagination]}
          expandableOpen={
            expandableThreeDesktop.isOpen ||
            expandableTwoMobile.isOpen ||
            expandableOneMobile.isOpen
          }
        >
          <SwiperSlide className="swiper-slide-mobile" key={1}>
            <ServiceBox
              ref={serviceBoxRef1Mobile}
              height={expandableOneMobile.height}
            >
              <StaticImage
                src="../../../images/services/box_1.jpg"
                style={{
                  width: "180px",
                  minHeight: "169px",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>Extending digital capability</BoxTitle>
                <BoxParagraph>
                  Our experts quickly adapt to new domains and technologies,
                  ready to join your team.
                </BoxParagraph>
              </TextContainer>
              <ExpandableBox
                display={expandableOneMobile.isOpen ? "flex" : "none"}
              >
                <ListItem background="#B1FCED">
                  <Bold>a</Bold>. We have expertise in all core IT areas, such
                  as software engineering, data, and cloud.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>b</Bold>. Our experts are specialised in complex and
                  business-critical projects.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>c</Bold>. Our experts master numerous domains and
                  industries such as finance, manufacturing, retail, energy, and
                  healthcare.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>d</Bold>. We are used to working together with
                  multi-vendor, distributed, and international teams.
                </ListItem>
              </ExpandableBox>
              <CTA
                onClick={expandableOneMobile.toggle}
                expandableOpen={expandableOneMobile.isOpen}
                color="#069D7F"
              >
                See how
              </CTA>
              <CTA
                onClick={expandableOneMobile.toggle}
                expandableOpen={!expandableOneMobile.isOpen}
                color="#069D7F"
              >
                Close
              </CTA>
            </ServiceBox>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-mobile" key={2}>
            <ServiceBox
              ref={serviceBoxRef2Mobile}
              height={expandableTwoMobile.height}
            >
              <StaticImage
                src="../../../images/services/box_2.jpg"
                style={{
                  width: "10.18125rem",
                  minHeight: "10.575rem",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>A dedicated product team</BoxTitle>
                <BoxParagraph>
                  Our skilled and versatile team will guide your product journey
                  from the first vision to a real digital solution.
                </BoxParagraph>
              </TextContainer>
              <ExpandableBox
                display={expandableTwoMobile.isOpen ? "flex" : "none"}
              >
                <ListItem background="#B1FCED">
                  <Bold>a</Bold>. We can assemble a team quickly; either
                  establish the team at once or scale it up incrementally.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>b</Bold>. We will always tailor the team roles and
                  expertise to the needed business case.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>c</Bold>. We will also guide you on how to lead product
                  development through the project.
                </ListItem>
              </ExpandableBox>
              <CTA
                onClick={expandableTwoMobile.toggle}
                expandableOpen={expandableTwoMobile.isOpen}
                color="#069D7F"
              >
                See how
              </CTA>
              <CTA
                onClick={expandableTwoMobile.toggle}
                expandableOpen={!expandableTwoMobile.isOpen}
                color="#069D7F"
              >
                Close
              </CTA>
            </ServiceBox>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-mobile" key={3}>
            <ServiceBox
              ref={serviceBoxRef3Mobile}
              height={expandableThreeMobile.height}
            >
              <StaticImage
                src="../../../images/services/box_3.jpg"
                style={{
                  width: "172px",
                  minHeight: "169px",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>Specialised services</BoxTitle>
                <BoxParagraph>
                  We offer expertise for specialized tasks like coaching,
                  architecture and audits.
                </BoxParagraph>
              </TextContainer>
              <ExpandableBox
                display={expandableThreeMobile.isOpen ? "flex" : "none"}
              >
                <ListItem background="#B1FCED">
                  <Bold>a</Bold>.We also undertake more specialised assignments,
                  such as architecture, security and design audits, team
                  coaching and support, and proof of concepts.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>b</Bold>. Our experts are used to working and solving
                  challenges independently.
                </ListItem>
                <ListItem background="#B1FCED">
                  <Bold>c</Bold>. Our compensation model allows our experts to
                  engage in shorter allocations or assignments.
                </ListItem>
              </ExpandableBox>
              <CTA
                onClick={expandableThreeMobile.toggle}
                expandableOpen={expandableThreeMobile.isOpen}
                color="#069D7F"
              >
                See how
              </CTA>
              <CTA
                onClick={expandableThreeMobile.toggle}
                expandableOpen={!expandableThreeMobile.isOpen}
                color="#069D7F"
              >
                Close
              </CTA>
            </ServiceBox>
          </SwiperSlide>
        </StyledSwiper>
      </MobileCarousel>
    </OurServicesSection>
  )
}

export default OurServices
