import React from "react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import { StaticImage } from "gatsby-plugin-image"

import {
  OurServicesSection,
  OurServicesTitle,
  TextContainer,
  BoxTitle,
  BoxParagraph,
  ServicesBoxesDesktop,
  ServiceBox,
  MobileCarousel,
  StyledSwiper,
  Bold,
} from "./StyledComponents"
import styled from "styled-components"

const StyledOurServicesSection = styled(OurServicesSection)`
  background: white;
  padding: 0;
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
  height: auto;

  @media (min-width: 64em) {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
`

const StyledServiceBox = styled(ServiceBox)`
  @media (max-width: 800px) {
    height: 400px;
  }
`

const WhyRebase = ({ data }) => {
  return (
    <StyledOurServicesSection>
      <OurServicesTitle>Why Choose Us</OurServicesTitle>
      <ServicesBoxesDesktop>
        <StyledServiceBox>
          <StaticImage
            className="responsive_icon"
            src="../../../images/services/responsive.jpg"
            style={{
              width: "200px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>Responsive</BoxTitle>
            <BoxParagraph>
              We will find you a <Bold>competent candidate</Bold> for your
              digital projects within <Bold>24 hours</Bold> and respond quickly
              to your mid-project needs and scaling.
            </BoxParagraph>
          </TextContainer>
        </StyledServiceBox>
        <StyledServiceBox>
          <StaticImage
            className="service-image"
            src="../../../images/services/spending.jpg"
            style={{
              width: "188px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>Smart spending</BoxTitle>
            <BoxParagraph>
              With Rebase, you pay for the consultant's skills, not the brand
              name.{" "}
              <Bold>80% of your payment goes directly to the consultant</Bold>,
              ensuring you can afford the best talent available.
            </BoxParagraph>
          </TextContainer>
        </StyledServiceBox>
        <StyledServiceBox>
          <StaticImage
            className="service-image"
            src="../../../images/services/loyal.jpg"
            style={{
              width: "192px",
              minHeight: "188px",
            }}
            quality={80}
            alt=""
          />
          <TextContainer>
            <BoxTitle>Loyal</BoxTitle>
            <BoxParagraph>
              Rebase satisfaction ratings are outstanding; our employee{" "}
              <Bold>
                NPS is +{data.enps}, and our customer NPS is +{data.cnps}
              </Bold>
              . Our senior experts guarantee a seamless and reliable project
              experience.
            </BoxParagraph>
          </TextContainer>
        </StyledServiceBox>
      </ServicesBoxesDesktop>

      <MobileCarousel>
        <StyledSwiper
          pagination={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          breakpoints={{
            400: {
              spaceBetween: 16,
            },
            500: {
              spaceBetween: 32,
            },
          }}
          className="mySwiper"
          modules={[Pagination]}
        >
          <SwiperSlide className="swiper-slide-mobile" key={1}>
            <StyledServiceBox>
              <StaticImage
                src="../../../images/services/responsive.jpg"
                style={{
                  width: "180px",
                  minHeight: "169px",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>Responsive</BoxTitle>
                <BoxParagraph>
                  We will find you a <Bold>competent candidate</Bold> for your
                  digital projects within <Bold>24 hours</Bold> and respond to
                  your mid-project needs and scaling.
                </BoxParagraph>
              </TextContainer>
            </StyledServiceBox>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-mobile" key={2}>
            <StyledServiceBox>
              <StaticImage
                src="../../../images/services/spending.jpg"
                style={{
                  width: "188px",
                  minHeight: "188px",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>Smart spending</BoxTitle>
                <BoxParagraph>
                  With Rebase, you pay for the consultant's skills, not the
                  brand name.{" "}
                  <Bold>
                    80% of your payment goes directly to the consultant
                  </Bold>
                  , ensuring you can afford the best talent available.
                </BoxParagraph>
              </TextContainer>
            </StyledServiceBox>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-mobile" key={3}>
            <StyledServiceBox>
              <StaticImage
                src="../../../images/services/loyal.jpg"
                style={{
                  width: "172px",
                  minHeight: "169px",
                }}
                quality={80}
                alt=""
              />
              <TextContainer>
                <BoxTitle>Loyal</BoxTitle>
                <BoxParagraph>
                  Rebase satisfaction ratings are outstanding; our employee{" "}
                  <Bold>
                    NPS is +{data.enps}, and our customer NPS is +{data.cnps}
                  </Bold>
                  . Our senior experts guarantee a seamless and reliable project
                  experience.
                </BoxParagraph>
              </TextContainer>
            </StyledServiceBox>
          </SwiperSlide>
        </StyledSwiper>
      </MobileCarousel>
    </StyledOurServicesSection>
  )
}

export default WhyRebase
