import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/common/Layout"
import Main from "../components/common/Main"
import Meta from "../components/common/Meta"
import { FindingRightConsultant } from "../components/common/downloadSection/FindingRightConsultant"
import ContactBox from "../components/common/formboxes/ContactBox"
import InfoBoxes from "../components/common/infoboxes/InfoBoxes"
import PageIntro from "../components/common/intro/PageIntro"
import Clouds from "../components/services/Clouds"
import HowWeOperate from "../components/services/HowWeOperate"
import OurServices from "../components/services/ExbandableBoxes/OurServices"
import SkillsSection from "../components/services/SkillsSection"
import WhyRebase from "../components/services/ExbandableBoxes/WhyRebase"

export const Head = () => (
  <Meta
    title="Rebase - Tailored Digital Services and Teams for Your Needs"
    description="Explore Rebase's tailored digital services: custom software development, cloud solutions, data and AI, test automation and business agility."
  />
)

const ServicesPage = ({ data }) => {
  const title = [["Best Solutions"], ["for Your Digital", "Challenges"]]

  return (
    <Layout>
      <Main>
        <PageIntro title={title} />
        <Clouds />
        <OurServices />
        <SkillsSection />
        <HowWeOperate peopleCount={data.people.totalCount} />
        <WhyRebase data={data.enps.nodes[0]} />
        <FindingRightConsultant dark={true} />
        <InfoBoxes
          content={[
            { number: 7.8, extra: "", content: "MEUR Net sales (2023)" },
            { number: 11, extra: "%", content: "EBITA (2023)" },
            { number: 65, extra: "%", content: "Growth (2023)" },
          ]}
        />
      </Main>
      <ContactBox />
    </Layout>
  )
}

export const query = graphql`
  query serviceQuery {
    people: allPeopleDataJson {
      totalCount
    }

    enps: allCommonJson {
      nodes {
        enps
        cnps
      }
    }
  }
`

export default ServicesPage
