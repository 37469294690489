import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ListComponent from "../common/ListComponent"

const StyledLink = styled(Link)`
  color: var(--clr-secondary-dark);
  font-weight: 600;
  display: contents;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }

  font-size: 1.125em;
`

const Bold = styled.span`
  font-weight: 600;
  display: contents;
`

const HowWeOperate = ({ peopleCount }) => {
  const items = [
    [
      <StyledLink key={1} to="#contact">
        Contact us
      </StyledLink>,
      " and we will discuss your challenges together.",
    ],
    [
      "We will provide an offer with ",
      <Bold key={2}>the best candidates</Bold>,
      " or a team within a few days.",
    ],
    [
      "We encourage you to ",
      <Bold key={3}>interview proposed experts</Bold>,
      " as well to further validate the skillset.",
    ],
    ["We offer ", <Bold key={4}>transparent and fair contracts</Bold>, "."],
    [
      "Our ",
      <Bold key={5}>systematic onboarding process</Bold>,
      " ensures smooth start of the project.",
    ],
    [
      "We ensure that the ",
      <Bold key={6}>project progresses</Bold>,
      " as planned and achieves its objectives.",
    ],
  ]

  const expandableItems = [
    undefined,
    [
      [
        <Bold key={"a"}>a.</Bold>,
        ` ${peopleCount} top experts, 1500 Gofore group specialists and a large expert network available.`,
      ],
      [
        <Bold key={"b"}>b.</Bold>,
        " We interview and validate each candidate’s technical and social capabilities.",
      ],
      [
        <Bold key={"c"}>c.</Bold>,
        " Needed solutions, prices, start date, workload and other terms and conditions will be included in the offer.",
      ],
    ],
    undefined,
    [
      [
        <Bold key={"a"}>a.</Bold>,
        " We provide transparent and fair contract templates suitable for IT projects.",
      ],
      [
        <Bold key={"b"}>b.</Bold>,
        " We have templates for necessary confidentiality agreements.",
      ],
      [
        <Bold key={"c"}>c.</Bold>,
        " We are flexible in tailoring our contracts to your needs or adopting to your contracts.",
      ],
    ],
    [
      [
        <Bold key={"a"}>a.</Bold>,
        " We ensure the practical aspects of the assignment, such as budgets, start dates, and work locations.",
      ],
      [
        <Bold key={"b"}>b.</Bold>,
        " We will initiate the required credentials and security clearances well in advance.",
      ],
      [
        <Bold key={"c"}>c.</Bold>,
        " We can facilitate a kick-off event, which helps to share the project vision and align the project's objectives.",
      ],
      [
        <Bold key={"d"}>d.</Bold>,
        " Our AI-based bot ensures a successful onboarding process for mutual success.",
      ],
    ],
    [
      [
        <Bold key={"a"}>a.</Bold>,
        " We transparently communicate the progress of the project and any potential changes.",
      ],
      [
        <Bold key={"b"}>b.</Bold>,
        " We regularly survey employee and customer satisfaction.",
      ],
      [<Bold key={"c"}>c.</Bold>, " We continuously improve our practices."],
    ],
  ]

  return (
    <ListComponent
      title="How We Operate"
      items={items}
      expandableItems={expandableItems}
    />
  )
}

export default HowWeOperate
