import { useState, useEffect, useCallback } from "react"

const useExpandableComponent = ref => {
  const [isOpen, setIsOpen] = useState(false)
  const [height, setHeight] = useState()

  const calculateHeight = useCallback(() => {
    if (ref?.current) {
      const children = ref.current.children
      let totalHeight = 0

      Array.from(children).forEach(child => {
        const childStyles = window.getComputedStyle(child)
        const childMarginTop = parseInt(childStyles.marginTop, 10)
        const childMarginBottom = parseInt(childStyles.marginBottom, 10)
        if (child.offsetHeight == 0) {
          totalHeight += child.offsetHeight
        } else {
          totalHeight += child.offsetHeight + childMarginTop + childMarginBottom
        }
      })
      setHeight(`${totalHeight}px`)
    }
  }, [ref])

  useEffect(() => {
    calculateHeight()
    window.addEventListener("resize", calculateHeight)

    return () => {
      window.removeEventListener("resize", calculateHeight)
    }
  }, [isOpen, calculateHeight]) // Recalculate when isOpen changes or on window resize

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return { isOpen, height, toggle }
}

export default useExpandableComponent
