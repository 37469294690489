import React from "react"
import styled from "styled-components"
import Cloud from "./Cloud"

const CloudSection = styled.section`
  display: flex;
  position: relative;
  width: 100%;
  height: 300px;
  margin-inline: auto;
  margin-top: 0;
  margin-bottom: 3rem;

  @media (min-width: 22em) {
    transform: scale(1.1);
  }

  @media (min-width: 50em) {
    transform: translateX(-50%) scale(0.8);
    left: 50%;
    width: 989px;
    height: 625px;
  }

  @media (min-width: 57em) {
    transform: translateX(-55%) scale(0.8);
  }

  @media (min-width: 65.5em) {
    margin-top: 1rem;
    margin-bottom: 7.5rem;
    transform: none;
    left: auto;
  }
`

const Clouds = () => {
  return (
    <CloudSection>
      <Cloud
        index={6}
        text="The current team is junior-heavy, and a senior expert is needed"
        top="92px"
        left="62px"
        animationIndex={0}
        color="#00493b"
        animationOrigin="left"
      />
      <Cloud
        index={2}
        text="There are more demanding needs, such as architectural, design, auditing, or coaching"
        top="0px"
        left="399px"
        animationIndex={1}
        color="#1008BD"
        leftText="29%"
        textPosition="translate(-14%,-50%)"
        animationOrigin="center"
      />
      <Cloud
        index={3}
        text="There is no in-house expertise on how to lead product development"
        top="350px"
        left="0px"
        animationIndex={2}
        color="#003a53"
      />
      <Cloud
        index={7}
        text="Product development needs correction due to missteps"
        top="289px"
        left="283px"
        animationIndex={3}
        color="#1008BD"
      />
      <Cloud
        index={4}
        text="The people don't have enough time to handle all the ICT work"
        top="74px"
        left="745px"
        animationIndex={4}
        color="#E00038"
        topText="52%"
        animationOrigin="right"
      />
      <Cloud
        index={1}
        text="The current IT-vendor is not performing in the best possible way"
        top="228px"
        left="567px"
        animationIndex={5}
        color="#00493b"
        leftText="29%"
        animationOrigin="center"
      />
      <Cloud
        index={8}
        text="In-house recruiting has not been successful"
        top="426px"
        left="470px"
        animationIndex={6}
        color="#E00038"
        leftText="35%"
        animationOrigin="bottom"
      />
      <Cloud
        index={5}
        text="The team needs a new team member or a replacement"
        top="396px"
        left="745px"
        animationIndex={7}
        color="#003a53"
        topText="52%"
        animationOrigin="bottom right"
      />
    </CloudSection>
  )
}

export default Clouds
